/* src/components/pages/Pages.css */
.page-container {
  min-height: calc(100vh - 80px);
  animation: fadeIn 0.5s ease;
}

.page-content {
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 2rem;
  color: white;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border: 1px solid rgba(255, 255, 255, 0.18);
  max-width: 800px;
  margin: 0 auto;
}

.content-section {
  margin-bottom: 2rem;
}

.content-section:last-child {
  margin-bottom: 0;
}
.form-group input {
  color: white;
}
h1 {
  font-size: 2.5rem;
  margin: 0 0 2rem;
  text-align: center;
}

h2 {
  font-size: 1.5rem;
  margin: 0 0 1rem;
  color: rgba(255, 255, 255, 0.9);
}

h3 {
  font-size: 1.2rem;
  margin: 1.5rem 0 1rem;
  color: rgba(255, 255, 255, 0.85);
}

p {
  line-height: 1.6;
  margin: 0 0 1rem;
  color: rgba(255, 255, 255, 0.8);
}

ul {
  margin: 1rem 0;
  padding-left: 1.5rem;
}

li {
  margin-bottom: 0.5rem;
  color: rgba(255, 255, 255, 0.8);
}

/* Contact Form Styles */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

label {
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.9rem;
}

input,
textarea {
  padding: 0.75rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.1);
  color: rgb(255, 255, 255);
  font-size: 1rem;
  transition: all 0.3s ease;
}
input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

textarea::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
input:focus,
textarea:focus {
  outline: none;
  border-color: rgba(255, 255, 255, 0.4);
  background: rgba(0, 0, 0, 0.15);
}

.button {
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
  align-self: flex-start;
}

.button:hover {
  background: rgba(255, 255, 255, 0.3);
}

.success-message {
  text-align: center;
  padding: 2rem;
}

.success-message h3 {
  margin-bottom: 1rem;
}

/* Contact Info Styles */
.contact-info {
  background: rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  border-radius: 12px;
  margin-top: 1rem;
}

.contact-info p {
  margin-bottom: 0.5rem;
}

.contact-info p:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .page-content {
    padding: 1.5rem;
    margin: 0 1rem;
  }

  h1 {
    font-size: 2rem;
  }

  .button {
    width: 100%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
