/* src/components/layout/Footer.css */
.footer {
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  color: white;
  padding: 3rem 0 1rem;
  margin-top: auto;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.footer-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

.footer-section img {
  width: 145px;
  height: auto;
  transform: scale(1.5);
}

.footer-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
}

.footer-description {
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.5;
  margin: 0;
}

.footer-subtitle {
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0;
}

.footer-nav {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.footer-link {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-link:hover {
  color: white;
}

.footer-credits {
  color: rgba(255, 255, 255, 0.7);
}

.footer-credits p {
  margin: 0.25rem 0;
}

.social-links {
  display: flex;
  gap: 1rem;
}

.social-link {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  transition: color 0.3s ease;
}

.social-link:hover {
  color: white;
}

.footer-bottom {
  max-width: 1200px;
  margin: 2rem auto 0;
  padding: 1rem 2rem 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.copyright {
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  margin: 0;
}

@media (max-width: 768px) {
  .footer {
    padding: 2rem 0 1rem;
  }

  .footer-content {
    grid-template-columns: 1fr;
    padding: 0 1rem;
    gap: 1.5rem;
  }

  .footer-section {
    text-align: center;
  }

  .footer-nav {
    align-items: center;
  }

  .social-links {
    justify-content: center;
  }

  .footer-bottom {
    margin-top: 1.5rem;
    padding: 1rem 1rem 0;
  }
}
