/* src/components/weather/DailyForecast.css */
.daily-forecast {
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 1.5rem;
  color: white;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border: 1px solid rgba(255, 255, 255, 0.18);
  margin: 1.5rem 0;
}

.daily-forecast h3 {
  margin: 0 0 1.5rem 0;
  font-size: 1.8rem;
  color: black;
  font-weight: 600;
  border-bottom: solid 1px rgba(255, 255, 255, 0.2);
}

.daily-forecast__container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
}

.forecast-day {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border-radius: 12px;
  background: rgba(27, 27, 27, 0.4);
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.forecast-day:hover {
  transform: translateY(-2px);
  background: rgba(255, 255, 255, 0.1);
}

.date-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.5rem;
}

.day {
  font-weight: 500;
  font-size: 1.1rem;
}

.date {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 0.2rem;
}

.weather-icon {
  width: 50px;
  height: 50px;
  margin: 0.5rem 0;
}

.temperature-range {
  display: flex;
  gap: 0.75rem;
  margin: 0.5rem 0;
  align-items: center;
}

.max-temp {
  font-size: 1.1rem;
  font-weight: 500;
}

.min-temp {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.7);
}

.description {
  text-align: center;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.8);
  text-transform: capitalize;
}

@media (max-width: 768px) {
  .daily-forecast {
    padding: 1rem;
  }

  .daily-forecast__container {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 0.75rem;
  }

  .forecast-day {
    padding: 0.75rem;
  }

  .day {
    font-size: 1rem;
  }

  .weather-icon {
    width: 40px;
    height: 40px;
  }
}
