/* src/components/pages/Home.css */
.home {
  animation: fadeIn 0.5s ease;
  margin-top: 40px;
}

.weather-grid {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: 2fr 2fr;
  margin-bottom: 1.5rem;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  color: white;
}

.loading-container .loading-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-top: 3px solid white;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}
.weather-description2 {
  margin-top: 20px;
  background: rgba(255, 255, 255, 0.15); /* Transparent white */
  backdrop-filter: blur(10px); /* Blur effect */
  -webkit-backdrop-filter: blur(10px); /* Safari support */
  border-radius: 10px; /* Rounded edges */
  border: 1px solid rgba(255, 255, 255, 0.2); /* Subtle border */
  padding: 10px;
}

.weather-description2 h1 {
  font-size: 2rem;
  font-weight: 600;
  padding: 10px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
}
.weather-description2 p {
  font-size: 1.2rem;
  padding: 10px;
}

.error-container {
  text-align: center;
  color: white;
  padding: 2rem;
}

.retry-button {
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1rem;
  transition: all 0.3s ease;
}

.retry-button:hover {
  background: rgba(255, 255, 255, 0.3);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .weather-grid {
    grid-template-columns: 1fr;
  }
}
