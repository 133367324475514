/* src/App.css */
.app {
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-image 1s ease;
}

.app-overlay {
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2));
}

.main-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 2rem 2rem;
  min-height: calc(100vh - 80px);
}

@media (max-width: 768px) {
  .main-content {
    padding: 120px 1rem 1rem;
  }
}
