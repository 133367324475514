/* src/components/weather/WeatherOverview.css */
.weather-overview {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20px);
  border-radius: 20px;
  padding: 2rem;
  color: white;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border: 1px solid rgba(255, 255, 255, 0.18);
  transition: transform 0.3s ease;
}

.weather-overview:hover {
  transform: translateY(-5px);
}

.weather-overview__header {
  margin-bottom: 1.5rem;
  border-bottom: solid 1px rgba(255, 255, 255, 0.2);
}

.weather-overview__header h2 {
  font-size: 2rem;
  margin: 0;
  font-weight: 600;
}

.weather-overview__header p {
  margin: 0.5rem 0 0;
  color: rgba(255, 255, 255, 0.8);
  font-size: 1rem;
}

.weather-overview__main {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.weather-icon {
  width: 100px;
  height: 100px;
  margin-right: 1rem;
}

.temperature-container {
  flex: 1;
}

.temperature-container h1 {
  font-size: 4rem;
  margin: 0;
  display: flex;
  align-items: start;
}

.unit-toggle {
  font-size: 1.5rem;
  margin-left: 0.5rem;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.unit-toggle:hover {
  opacity: 1;
}

.weather-description {
  margin: 0;
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.2rem;
  text-transform: capitalize;
}

.weather-overview__details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  padding-top: 1rem;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 15px 5px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.detail-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.detail-label {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0.5rem;
}

.detail-value {
  font-size: 1.2rem;
  font-weight: 500;
}

@media (max-width: 768px) {
  .weather-overview {
    padding: 1.5rem;
  }

  .weather-overview__header h2 {
    font-size: 1.5rem;
  }

  .temperature-container h1 {
    font-size: 3rem;
  }

  .weather-icon {
    width: 80px;
    height: 80px;
  }

  .weather-overview__details {
    gap: 1rem;
  }
}
