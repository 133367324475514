/* src/components/weather/HourlyForecast.css */
.hourly-forecast {
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 1.5rem;
  color: white;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border: 1px solid rgba(255, 255, 255, 0.18);
  margin: 1.5rem 0;
}

.hourly-forecast h3 {
  margin: 0 0 1.5rem 0;
  font-size: 1.8rem;
  color: black;
  font-weight: 600;
  border-bottom: solid 1px rgba(255, 255, 255, 0.2);
}

.hourly-forecast__container {
  display: flex;
  gap: 1.5rem;
  overflow-x: auto;
  padding-bottom: 0.5rem;
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.3) transparent;
}

.hourly-forecast__container::-webkit-scrollbar {
  height: 4px;
}

.hourly-forecast__container::-webkit-scrollbar-track {
  background: transparent;
}

.hourly-forecast__container::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 2px;
}

.forecast-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 105px;
  padding: 0.5rem;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
}

.forecast-item:hover {
  transform: translateY(-2px);
  background: rgba(255, 255, 255, 0.1);
}

.forecast-item .time {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 0.5rem;
}

.forecast-item .weather-icon {
  width: 40px;
  height: 40px;
  margin: 0.5rem 0;
}

.forecast-item .temp {
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0.5rem 0;
}

.forecast-item .wind,
.forecast-item .humidity {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
}

@media (max-width: 768px) {
  .hourly-forecast {
    padding: 1rem;
  }

  .forecast-item {
    min-width: 70px;
  }

  .forecast-item .weather-icon {
    width: 35px;
    height: 35px;
  }
}
