/* src/components/weather/SunMoonInfo.css */
.sun-moon-info {
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 1.5rem;
  color: white;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

/* Tabs */
.tabs {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 0.5rem;
}

.tab {
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.7);
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  font-size: 1rem;
  position: relative;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.tab:hover {
  color: white;
}

.tab.active {
  color: white;
}

.tab.active::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  height: 2px;
  background: white;
  border-radius: 2px;
}

.tab-icon {
  font-size: 1.2rem;
}

/* Sun Visualization */
.sun-arc-container {
  margin: 2rem 0;
}

.sun-arc {
  position: relative;
  height: 120px;
  margin: 0 1rem;
  overflow: hidden;
}

.sun-arc::before {
  content: "";
  position: absolute;
  top: 0;
  left: -10%;
  width: 120%;
  height: 200%;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.15);
}

.sun-indicator {
  position: absolute;
  top: 5px;
  width: 30px;
  height: 30px;
  background: #ffd700;
  border-radius: 50%;
  transform: translateX(-50%);
  box-shadow: 0 0 30px rgba(255, 215, 0, 0.6);
  animation: sunGlow 2s infinite ease-in-out;
}

.time-markers {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
}

/* Sun Information */
.sun-info-grid {
  display: grid;
  gap: 1.5rem;
}

.sun-info-primary {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
}

.current-period {
  display: flex;
  flex-direction: column;
}

.period-label {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.remaining-time {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
}

.sun-time-details {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.info-item {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
}

.icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.sunrise-icon {
  background: linear-gradient(45deg, #ffd700, #ffa500);
}

.sunset-icon {
  background: linear-gradient(45deg, #ffa500, #ff4500);
}

/* Moon Visualization */
.moon-phase-visual {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
}

.moon-circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: #1a1a1a;
  position: relative;
  overflow: hidden;
  box-shadow: inset -8px -8px 12px rgba(0, 0, 0, 0.8),
    inset 8px 8px 12px rgba(255, 255, 255, 0.4),
    0 0 20px rgba(255, 255, 255, 0.1);
}

.moon-phase-indicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f4f6f8;
  transition: transform 0.3s ease;
}

/* UV Index */
.uv-index-container {
  padding: 1rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
}

.uv-index-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.uv-value {
  font-size: 1.5rem;
  font-weight: 600;
}

.uv-index-bar {
  position: relative;
  height: 8px;
  background: linear-gradient(
    to right,
    #6aff00 0%,
    #f9a825 25%,
    #ef6c00 50%,
    #b71c1c 75%,
    #6a1b9a 100%
  );
  border-radius: 4px;
  margin: 1rem 0;
}

.uv-index-indicator {
  position: absolute;
  top: 50%;
  width: 12px;
  height: 12px;
  background: white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.uv-level {
  display: block;
  text-align: center;
  margin-top: 0.5rem;
  font-weight: 500;
}

/* Common Elements */
.label {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0.25rem;
}

.value {
  font-size: 1.1rem;
  font-weight: 500;
}

/* Moon Info Grid */
.moon-info-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin-top: 1rem;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
}

/* Animations */
@keyframes sunGlow {
  0% {
    box-shadow: 0 0 20px rgba(255, 215, 0, 0.5);
  }
  50% {
    box-shadow: 0 0 30px rgba(255, 215, 0, 0.7);
  }
  100% {
    box-shadow: 0 0 20px rgba(255, 215, 0, 0.5);
  }
}

/* Day/Night Transitions */
.sun-moon-info.night-mode {
  background: rgba(0, 0, 0, 0.3);
}

.sun-moon-info.day-mode {
  background: rgba(255, 255, 255, 0.15);
}

/* Responsive Design */
@media (max-width: 768px) {
  .sun-moon-info {
    padding: 1rem;
  }

  .sun-arc {
    height: 100px;
  }

  .sun-indicator {
    width: 24px;
    height: 24px;
  }

  .sun-time-details {
    flex-direction: column;
  }

  .moon-circle {
    width: 100px;
    height: 100px;
  }

  .info-item {
    width: 100%;
  }

  .sun-info-primary {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }

  .moon-info-grid {
    grid-template-columns: 1fr;
  }

  .tab-text {
    display: none;
  }

  .tab {
    padding: 0.5rem 1rem;
  }

  .tab-icon {
    font-size: 1.5rem;
  }
}

/* Additional Effects */
.content {
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Hover Effects */
.info-item:hover {
  background: rgba(255, 255, 255, 0.15);
  transform: translateY(-2px);
  transition: all 0.3s ease;
}

/* Focus States for Accessibility */
.tab:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
}

/* Dark Mode Enhancements */
@media (prefers-color-scheme: dark) {
  .sun-moon-info {
    background: rgba(0, 0, 0, 0.4);
  }

  .info-item {
    background: rgba(255, 255, 255, 0.08);
  }
}

/* Print Styles */
@media print {
  .sun-moon-info {
    background: none;
    color: black;
    box-shadow: none;
  }

  .info-item {
    border: 1px solid #ccc;
  }
}

/* Add to your SunMoonInfo.css file */

.moon-content {
  padding: 1.5rem 0;
}

.moon-phase-visual {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.moon-display {
  position: relative;
  width: 150px;
  height: 150px;
  margin: 0 auto;
}

.moon-circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(145deg, #f4f6f8, #e0e2e4);
  position: relative;
  overflow: hidden;
  box-shadow: inset -10px -10px 15px rgba(0, 0, 0, 0.3),
    inset 10px 10px 15px rgba(255, 255, 255, 0.5),
    0 0 30px rgba(255, 255, 255, 0.2);
}

.moon-crater {
  position: absolute;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1);
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.crater-1 {
  width: 30px;
  height: 30px;
  top: 30%;
  left: 25%;
}

.crater-2 {
  width: 20px;
  height: 20px;
  top: 50%;
  right: 30%;
}

.crater-3 {
  width: 25px;
  height: 25px;
  bottom: 20%;
  left: 40%;
}

.moon-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.8);
  clip-path: polygon(0 0, 50% 0, 50% 100%, 0 100%);
  transform-origin: center;
  transition: transform 0.3s ease;
}

.moon-phase-progress {
  width: 100%;
  padding: 0 1rem;
}

.progress-points {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  position: relative;
}

.point {
  width: 12px;
  height: 12px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  position: relative;
  transition: all 0.3s ease;
}

.point.active {
  background: #fff;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

.point-label {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
  white-space: nowrap;
}

.progress-bar {
  height: 4px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background: linear-gradient(to right, #ffd700, #fff);
  border-radius: 2px;
  transition: width 0.3s ease;
}

.moon-details {
  margin-top: 2rem;
}

.moon-info-card {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 1.5rem;
  text-align: center;
  margin-bottom: 1.5rem;
}

.info-header {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0.5rem;
}

.info-value {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.info-subtext {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.8);
}

.moon-info-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.moon-time-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  transition: transform 0.3s ease;
}

.moon-time-item:hover {
  transform: translateY(-2px);
  background: rgba(255, 255, 255, 0.15);
}

.moon-time-icon {
  font-size: 1.5rem;
}

.moon-time-details {
  display: flex;
  flex-direction: column;
}

/* Animation for moon phase */
@keyframes moonGlow {
  0% {
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.3);
  }
  100% {
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.2);
  }
}

.moon-circle {
  animation: moonGlow 4s infinite ease-in-out;
}

/* Responsive Design */
@media (max-width: 768px) {
  .moon-display {
    width: 120px;
    height: 120px;
  }

  .point-label {
    font-size: 0.7rem;
  }

  .moon-info-grid {
    grid-template-columns: 1fr;
  }
}

/* Dark mode enhancement */
@media (prefers-color-scheme: dark) {
  .moon-circle {
    background: linear-gradient(145deg, #e0e2e4, #d0d2d4);
  }
}
